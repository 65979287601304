import { HttpRequestHelpers } from 'apriori-react-core';

import {
  ACCOUNT_SELECTED,
  ACTIVATE_ACCOUNT_FAILED,
  ACTIVATE_ACCOUNT_PENDING,
  ACTIVATE_ACCOUNT_SUCCEEDED,
  ADD_ACCOUNT_FAILED,
  ADD_ACCOUNT_PENDING,
  ADD_ACCOUNT_SUCCEEDED,
  DELETE_ACCOUNT_FAILED,
  DELETE_ACCOUNT_PENDING,
  DELETE_ACCOUNT_SUCCEEDED,
  GET_ACTIVE_ACCOUNT_FAILED,
  GET_ACTIVE_ACCOUNT_PENDING,
  GET_ACTIVE_ACCOUNT_SUCCEEDED,
  LIST_ACCOUNTS_FAILED,
  LIST_ACCOUNTS_PENDING,
  LIST_ACCOUNTS_SUCCEEDED,
  UPDATE_ACCOUNT_FAILED,
  UPDATE_ACCOUNT_PENDING,
  UPDATE_ACCOUNT_SUCCEEDED
} from '../constants/actions';

import { ACCOUNTS_URL, ACTIVE_ACCOUNT_URL } from '../constants/endpoints';

export const activateAccount = (identity) => async (dispatch) => {

  dispatch(activateAccountPending());

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = `${ACCOUNTS_URL}/${identity}/activate`;


  return HttpRequestHelpers.post(url, null, headers)
    .then(response => {
      dispatch(activateAccountSucceeded());
      dispatch(getActiveAccount());
      dispatch(listAccounts());
      return response;
    })
    .catch(error => {
      dispatch(activateAccountFailed());
      console.log(error);
    });
};

export const addAccount = (account) => async (dispatch) => {

  dispatch(addAccountPending());

  const body = JSON.stringify(account);

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = ACCOUNTS_URL;

  return HttpRequestHelpers.post(url, body, headers)
    .then(response => {
      dispatch(addAccountSucceeded());
      dispatch(listAccounts());
      return response;
    })
    .catch(error => {
      dispatch(addAccountFailed());
      console.log(error);
    });
};

export const deleteAccount = (identity) => async (dispatch) => {

  dispatch(deleteAccountPending());

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = `${ACCOUNTS_URL}/${identity}`;

  return HttpRequestHelpers.$delete(url, headers)
    .then(() => {
      dispatch(deleteAccountSucceeded());
      dispatch(getActiveAccount());
      dispatch(listAccounts());
    })
    .catch(error => {
      dispatch(deleteAccountFailed());
      console.log(error);
    });
};

export const getActiveAccount = () => async (dispatch) => {

  dispatch(getActiveAccountPending());

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = ACTIVE_ACCOUNT_URL;

  return HttpRequestHelpers.get(url, headers)
    .then(response => {
      dispatch(getActiveAccountSucceeded(response.response));
      return response;
    })
    .catch(error => {
      dispatch(getActiveAccountFailed());
      console.log(error);
    });
};

export const listAccounts = () => async (dispatch) => {

  dispatch(listAccountsPending());

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = ACCOUNTS_URL;

  return HttpRequestHelpers.get(url, headers)
    .then(response => {
      dispatch(listAccountsSucceeded(response.response));
      return response;
    })
    .catch(error => {
      dispatch(listAccountsFailed());
      console.log(error);
    });
};

export const updateAccount = (identity, account) => async (dispatch) => {

  dispatch(updateAccountPending());

  const body = JSON.stringify(account);

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = `${ACCOUNTS_URL}/${identity}`;

  return HttpRequestHelpers.patch(url, body, headers)
    .then(response => {
      dispatch(updateAccountSucceeded());
      dispatch(getActiveAccount());
      dispatch(listAccounts());
      return response;
    })
    .catch(error => {
      dispatch(updateAccountFailed());
      console.log(error);
    });
};

export const selectAccount = (identity) => async (dispatch) => {
  dispatch(accountSelected(identity));
};

const accountSelected = (identity) => ({
  type: ACCOUNT_SELECTED,
  payload: identity
});

const activateAccountFailed = () => ({
  type: ACTIVATE_ACCOUNT_FAILED,
  payload: null
});

const activateAccountPending = () => ({
  type: ACTIVATE_ACCOUNT_PENDING,
  payload: null
});

const activateAccountSucceeded = accountStatus => ({
  type: ACTIVATE_ACCOUNT_SUCCEEDED,
  payload: accountStatus
});

const addAccountFailed = () => ({
  type: ADD_ACCOUNT_FAILED,
  payload: null
});

const addAccountPending = () => ({
  type: ADD_ACCOUNT_PENDING,
  payload: null
});

const addAccountSucceeded = () => ({
  type: ADD_ACCOUNT_SUCCEEDED,
  payload: null
});

const deleteAccountFailed = () => ({
  type: DELETE_ACCOUNT_FAILED,
  payload: null
});

const deleteAccountPending = () => ({
  type: DELETE_ACCOUNT_PENDING,
  payload: null
});

const deleteAccountSucceeded = accountStatus => ({
  type: DELETE_ACCOUNT_SUCCEEDED,
  payload: accountStatus
});

const getActiveAccountFailed = () => ({
  type: GET_ACTIVE_ACCOUNT_FAILED,
  payload: null
});

const getActiveAccountPending = () => ({
  type: GET_ACTIVE_ACCOUNT_PENDING,
  payload: null
});

const getActiveAccountSucceeded = activeAccount => ({
  type: GET_ACTIVE_ACCOUNT_SUCCEEDED,
  payload: activeAccount
});

const listAccountsFailed = () => ({
  type: LIST_ACCOUNTS_FAILED,
  payload: null
});

const listAccountsPending = () => ({
  type: LIST_ACCOUNTS_PENDING,
  payload: null
});

const listAccountsSucceeded = accounts => ({
  type: LIST_ACCOUNTS_SUCCEEDED,
  payload: accounts
});

const updateAccountFailed = () => ({
  type: UPDATE_ACCOUNT_FAILED,
  payload: null
});

const updateAccountPending = () => ({
  type: UPDATE_ACCOUNT_PENDING,
  payload: null
});

const updateAccountSucceeded = () => ({
  type: UPDATE_ACCOUNT_SUCCEEDED,
  payload: null
});
