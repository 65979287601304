//
// The following message constants are organized by component hierarchy.
// For example, a message constant that appears in `AccountForm`
// would reside under the 'Account messages' section.
//
// If a message constant should be reused across different components,
// it should reside under the most relevant section.
//
// However, if a message constant is completely dissociated,
// it should reside under the 'Generic' section.
//

// Generic messages.
export const UNABLE_TO_RENDER_FORM = 'Unable to render form';

// Account messages.
export const ACCOUNTS = 'Accounts';
export const ADD_NEW_ACCOUNT = 'Add a new account';
export const CHECKING_YOUR_ACCOUNTS = 'Checking your accounts...';
export const INVALID_ACTIVE_ACCOUNT_CREDENTIALS = 'The active account credentials are invalid';
export const INVALID_ACCOUNT_CREDENTIALS = 'Account credentials are invalid';
export const NO_ACCOUNTS_FOUND = 'No accounts found. Use the "Add a new account" button to enter account details.';
export const PART_REQUESTS_USED = 'part requests used';
export const PART_REQUESTS_USED_UNLIMITED = 'unique part requests used (unlimited).'
export const RETRIEVING_ACTIVE_ACCOUNT = 'Retrieving the active account...';
export const RETRIEVING_ACTIVE_ACCOUNT_FAILED = 'Error retrieving the active account';
export const VALID_ACCOUNT_CREDENTIALS = 'Account credentials are valid';

// Authentication messages.
export const FAILED_TO_REFRESH_SESSION = 'Failed to refresh session.';
export const UNABLE_TO_DECODE_JWT = 'Unable to decode JWT token, ensure the supplied JWT token is in a valid format.';
export const UNABLE_TO_RETRIEVE_USER_PROFILE = 'Unable to retrieve a user profile to validate your session, please try again or contact your system administrator.';
export const RETRY = 'Retry';
export const SOMETHING_WENT_WRONG_TITLE = 'Something went wrong';
export const SOMETHING_WENT_WRONG_MESSAGE = 'Your login attempt has failed, please try again or contact your system administrator.';

// Bill of Materials messages.
export const DROPZONE_SUBTITLE = "Only files with the extension '.csv' will be accepted";
export const DROPZONE_TITLE = "Drag 'n' drop, or click to select, to upload a Bill of Materials";
export const NO_BILL_OF_MATERIALS_UPLOADED = 'No bill of materials have been previously uploaded';
export const UPLOAD_PCBA_SUB_TEXT = 'Upload a Bill of Materials containing Line Items for PCBA.';
export const UPLOAD_PCBA_TEXT = 'Upload PCBA';
export const UPLOAD_WH_SUB_TEXT = 'Upload a Bill of Materials containing Line Items for Wire Harness.';
export const UPLOAD_WH_TEXT = 'Upload Wire Harness';

// Header messages.
export const APP_NAME = 'Electronics Data Collection';
export const MANAGE_ACCOUNTS = 'Manage Accounts';

// Footer messages.
export const COPYRIGHT_START_YEAR = '2018';

// Line Item messages.
export const ASK_FOR_SELECTION = 'Please select one for export.';
export const COST_STATUS = 'Cost status';
export const COSTED = 'Costed';
export const EXPORT_BILL_OF_MATERIALS = 'Export bill of materials';
export const FILTER_PARTS = 'Filter parts';
export const INCOMPLETE = 'Incomplete';
export const INCOMPLETE_MSG = 'Match - Action Needed';
export const INCOMPLETE_MULTIPLE_MATCHES_MSG = 'Match - Multiple Matches';
export const NO_LINE_ITEMS_FOUND = 'No line items found';
export const NO_PARTS_MATCHED = 'No Parts Matched';
export const NO_PARTS_MATCHED_MSG = 'No Match';
export const NOT_COSTED = 'Not Costed';
export const NUMBER_OF_OPTIONS = " Possible Matches";
export const READY_FOR_EXPORT = 'Ready for Export';
export const READY_FOR_EXPORT_MSG = 'Match - Complete';

// Modal messages.
export const ARE_YOU_SURE = 'Are you sure?';
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE = (name) => `You are about to delete ${name}, are you sure you want to do this? This action cannot be un-done.`;
export const YOU_ARE_ABOUT_TO_ACTIVATE_ACCOUNT = (name) => `You are about to activate ${name}, requests for part information will use this account quota.`;

// Part messages.
export const ADD_CUSTOM_PART = 'Add a custom part';
export const AVAILABILITY = 'Availability';
export const AVERAGE_COST = 'Average Cost';
export const CLASSIFICATION = 'Classification';
export const CLEAR_PART_SELECTION = 'Clear part selection';
export const COST_PARTS = 'Cost parts';
export const DATASHEET_URL = 'DataSheet URL';
export const DESCRIPTION = 'Description';
export const EDIT_PART = (partName) => `Edit ${partName}`;
export const MANUFACTURER = 'Manufacturer';
export const MANUFACTURER_PART_NUMBER = 'Manufacturer Part Number';
export const MATCHES_FOR_PART = (partName) => `Matches for ${partName}`;
export const MINIMUM_COST = 'Minimum Cost';
export const MOUNT_TYPE = 'Mount Type';
export const PIN_COUNT = 'Pin Count';
export const ROHS = 'RoHS';
export const ROHS_VERSION = 'RoHS Version';
export const SELECT_LINE_ITEM_TO_BEGIN = 'Select a line item to begin';
export const SELECT_PART_FOR_EXPORT = 'Select part for export';

// User Management messages.
export const MY_PROFILE = 'My Profile';
export const UNABLE_TO_LOAD_USER_PROFILE = 'Unable to load user profile';
export const UPLOAD_BOM = 'Upload BOM';
