import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';

import { Messages } from 'apriori-react-core';

import {
  ADD_CUSTOM_PART,
  AVAILABILITY,
  AVERAGE_COST,
  CLASSIFICATION,
  DATASHEET_URL,
  DESCRIPTION,
  EDIT_PART,
  MANUFACTURER,
  MANUFACTURER_PART_NUMBER,
  MINIMUM_COST,
  MOUNT_TYPE,
  PIN_COUNT,
  ROHS,
  ROHS_VERSION
} from '../../constants/messages';

import {
  validateAvailability,
  validateAverageCost,
  validateClassification,
  validateDataSheetUrl,
  validateDescription,
  validateManufacturer,
  validateManufacturerPartNumber,
  validateMinimumCost,
  validateMountType,
  validatePinCount,
  validateRohs,
  validateRohsVersion
} from '../../helpers/part-validation-helper';

const initialState = {
  availability: null,
  averageCost: null,
  classification: null,
  dataSheetUrl: null,
  description: null,
  manufacturer: null,
  manufacturerPartNumber: null,
  minimumCost: null,
  mountType: null,
  pinCount: null,
  rohs: null,
  rohsVersion: null,
  formErrors: {
    availability: null,
    averageCost: null,
    classification: null,
    dataSheetUrl: null,
    description: null,
    manufacturer: null,
    manufacturerPartNumber: null,
    minimumCost: null,
    mountType: null,
    pinCount: null,
    rohs: null,
    rohsVersion: null
  },
  formValidation: {
    availability: true,
    averageCost: false,
    classification: true,
    dataSheetUrl: true,
    description: true,
    manufacturer: true,
    manufacturerPartNumber: false,
    minimumCost: true,
    mountType: true,
    pinCount: true,
    rohs: true,
    rohsVersion: true
  },
  isDirty: false,
  panelTitle: ADD_CUSTOM_PART,
  wasSuppliedPart: false
};

class PartForm extends Component {

  constructor(props) {
    super(props);
    this.state = { ...initialState };
  };

  componentWillReceiveProps(nextProps) {
    const { part, type } = nextProps;

    let newState = { ...initialState };

    // If this is a PCBA part, mountType and pinCount are required.
    if (type === 'PCBA') {
      newState = {
        ...newState,
        formValidation: {
          ...newState.formValidation,
          mountType: false,
          pinCount: false
        }
      }
    }

    if (part) {
      newState = Object.assign(
        newState,
        {
          availability: part.availability,
          averageCost: part.averageCost,
          classification: part.classification,
          dataSheetUrl: part.dataSheetUrl,
          description: part.description,
          manufacturer: part.manufacturer,
          manufacturerPartNumber: part.manufacturerPartNumber,
          minimumCost: part.minimumCost,
          mountType: part.mountType,
          pinCount: part.pinCount,
          rohs: part.rohs,
          rohsVersion: part.rohsVersion,
          panelTitle: EDIT_PART(part.manufacturerPartNumber),
          wasSuppliedPart: true
        }
      );

      // `setState` is asynchronous by nature therefore
      // perform validation in the `setState` callback
      // to ensure the state has been set before validating the form.
      this.setState(newState, () => this.validateForm());
    }
    else {
      this.setState(newState);
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    let newValue = value;
    if (value === '') {
      newValue = null;
    }

    this.setState({ [name]: newValue, isDirty: true });
    this.validateFormField(name, newValue);
  };

  isFormDirty = () => {
    const { isDirty } = this.state;
    return isDirty;
  };

  isFormValid = () => {
    const { formValidation } = this.state;
    return (
      formValidation.availability &&
      formValidation.averageCost &&
      formValidation.classification &&
      formValidation.dataSheetUrl &&
      formValidation.description &&
      formValidation.manufacturer &&
      formValidation.manufacturerPartNumber &&
      formValidation.minimumCost &&
      formValidation.mountType &&
      formValidation.pinCount &&
      formValidation.rohs &&
      formValidation.rohsVersion
    );
  };

  save = () => {
    const { addPart, billOfMaterials, lineItemIdentity, part, updatePart, toggle } = this.props;
    const {
      availability,
      averageCost,
      classification,
      dataSheetUrl,
      description,
      manufacturer,
      manufacturerPartNumber,
      minimumCost,
      mountType,
      pinCount,
      rohs,
      rohsVersion,
      wasSuppliedPart
    } = this.state;

    const newPart = {
      availability,
      averageCost,
      classification,
      dataSheetUrl,
      description,
      manufacturer,
      manufacturerPartNumber,
      minimumCost,
      mountType,
      pinCount,
      rohs,
      rohsVersion
    };

    if (part && part.isSaved) {
      newPart.isUserPart = part.isUserPart;
      updatePart(billOfMaterials.identity, lineItemIdentity, part.identity, newPart);
    }
    else {
      newPart.isUserPart = true;

      if (wasSuppliedPart) {
        newPart.externalId = part.externalId;
        newPart.isUserPart = false;
      }

      addPart(billOfMaterials.identity, lineItemIdentity, newPart);
    }

    toggle();
  };

  validateForm = () => {
    const { type } = this.props;
    const {
      availability,
      averageCost,
      classification,
      dataSheetUrl,
      description,
      manufacturer,
      manufacturerPartNumber,
      minimumCost,
      mountType,
      pinCount,
      rohs,
      rohsVersion
    } = this.state;

    this.validateFormField('availability', availability);
    this.validateFormField('averageCost', averageCost);
    this.validateFormField('classification', classification);
    this.validateFormField('dataSheetUrl', dataSheetUrl);
    this.validateFormField('description', description);
    this.validateFormField('manufacturer', manufacturer);
    this.validateFormField('manufacturerPartNumber', manufacturerPartNumber);
    this.validateFormField('minimumCost', minimumCost);
    this.validateFormField('rohs', rohs);
    this.validateFormField('rohsVersion', rohsVersion);

    if (type === 'PCBA') {
      this.validateFormField('mountType', mountType);
      this.validateFormField('pinCount', pinCount);
    }
  };

  validateFormField = (name, value) => {
    let error;

    switch(name) {
      case 'availability':
        error = validateAvailability(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, availability: error },
          formValidation: { ...prevState.formValidation, availability: !error }
        }));
        break;

      case 'averageCost':
        error = validateAverageCost(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, averageCost: error },
          formValidation: { ...prevState.formValidation, averageCost: !error }
        }));
        break;

      case 'classification':
        error = validateClassification(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, classification: error },
          formValidation: { ...prevState.formValidation, classification: !error }
        }));
        break;

      case 'dataSheetUrl':
        error = validateDataSheetUrl(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, dataSheetUrl: error },
          formValidation: { ...prevState.formValidation, dataSheetUrl: !error }
        }));
        break;

      case 'description':
        error = validateDescription(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, description: error },
          formValidation: { ...prevState.formValidation, description: !error }
        }));
        break;

      case 'manufacturer':
        error = validateManufacturer(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, manufacturer: error },
          formValidation: { ...prevState.formValidation, manufacturer: !error }
        }));
        break;

      case 'manufacturerPartNumber':
        error = validateManufacturerPartNumber(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, manufacturerPartNumber: error },
          formValidation: { ...prevState.formValidation, manufacturerPartNumber: !error }
        }));
        break;

      case 'minimumCost':
        error = validateMinimumCost(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, minimumCost: error },
          formValidation: { ...prevState.formValidation, minimumCost: !error }
        }));
        break;

      case 'mountType':
        error = validateMountType(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, mountType: error },
          formValidation: { ...prevState.formValidation, mountType: !error }
        }));
        break;

      case 'pinCount':
        error = validatePinCount(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, pinCount: error },
          formValidation: { ...prevState.formValidation, pinCount: !error }
        }));
        break;

      case 'rohs':
        error = validateRohs(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, rohs: error },
          formValidation: { ...prevState.formValidation, rohs: !error }
        }));
        break;

      case 'rohsVersion':
        error = validateRohsVersion(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, rohsVersion: error },
          formValidation: { ...prevState.formValidation, rohsVersion: !error }
        }));
        break;

      default:
        break;
    }
  };

  renderPcba() {
    const { type } = this.props;
    const { formErrors, mountType, pinCount } = this.state;

    if (type !== 'PCBA') {
      return;
    }

    return (
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label className='form-label required'>{MOUNT_TYPE}</Label>
            <InputGroup className={(formErrors.mountType ? 'is-invalid' : '')}>
              <Input
                type='text'
                name='mountType'
                id='mount-type'
                placeholder={MOUNT_TYPE}
                value={mountType}
                className={(formErrors.mountType ? 'is-invalid' : '')}
                onChange={this.handleChange}
              />
            </InputGroup>
            <FormFeedback>{formErrors.mountType}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label className='form-label required'>{PIN_COUNT}</Label>
            <InputGroup className={(formErrors.pinCount ? 'is-invalid' : '')}>
              <Input
                type='text'
                name='pinCount'
                id='pin-count'
                placeholder={PIN_COUNT}
                value={pinCount}
                className={(formErrors.pinCount ? 'is-invalid' : '')}
                onChange={this.handleChange}
              />
            </InputGroup>
            <FormFeedback>{formErrors.pinCount}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderForm() {
    const {
      availability,
      averageCost,
      classification,
      dataSheetUrl,
      description,
      formErrors,
      manufacturer,
      manufacturerPartNumber,
      minimumCost,
      rohs,
      rohsVersion
    } = this.state;

    return (
      <Form key='add-part-form' className='full-width'>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label required'>{AVERAGE_COST}</Label>
              <InputGroup className={(formErrors.averageCost ? 'is-invalid' : '')}>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  type='text'
                  name='averageCost'
                  id='average-cost'
                  placeholder={AVERAGE_COST}
                  value={averageCost}
                  className={(formErrors.averageCost ? 'is-invalid' : '')}
                  onChange={this.handleChange}
                />
              </InputGroup>
              <FormFeedback>{formErrors.averageCost}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label'>{MINIMUM_COST}</Label>
              <InputGroup className={(formErrors.minimumCost ? 'is-invalid' : '')}>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  type='text'
                  name='minimumCost'
                  id='minimum-cost'
                  placeholder={MINIMUM_COST}
                  value={minimumCost}
                  className={(formErrors.minimumCost ? 'is-invalid' : '')}
                  onChange={this.handleChange}
                />
              </InputGroup>
              <FormFeedback>{formErrors.minimumCost}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {this.renderPcba()}
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label required'>{MANUFACTURER_PART_NUMBER}</Label>
              <Input
                type='text'
                name='manufacturerPartNumber'
                id='manufacturer-part-number'
                placeholder={MANUFACTURER_PART_NUMBER}
                value={manufacturerPartNumber}
                className={(formErrors.manufacturerPartNumber ? 'is-invalid' : '')}
                onChange={this.handleChange}
              />
              <FormFeedback>{formErrors.manufacturerPartNumber}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label'>{MANUFACTURER}</Label>
              <Input
                type='text'
                name='manufacturer'
                id='manufacturer'
                placeholder={MANUFACTURER}
                value={manufacturer}
                className={(formErrors.manufacturer ? 'is-invalid' : '')}
                onChange={this.handleChange}
              />
              <FormFeedback>{formErrors.manufacturer}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label className='form-label'>{CLASSIFICATION}</Label>
          <Input
            type='text'
            name='classification'
            id='classification'
            placeholder={CLASSIFICATION}
            value={classification}
            className={(formErrors.classification ? 'is-invalid' : '')}
            onChange={this.handleChange}
          />
          <FormFeedback>{formErrors.classification}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className='form-label'>{DATASHEET_URL}</Label>
          <Input
            type='text'
            name='dataSheetUrl'
            id='dataSheetUrl'
            placeholder={DATASHEET_URL}
            value={dataSheetUrl}
            className={(formErrors.dataSheetUrl ? 'is-invalid' : '')}
            onChange={this.handleChange}
          />
          <FormFeedback>{formErrors.dataSheetUrl}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className='form-label'>{AVAILABILITY}</Label>
          <Input
            type='text'
            name='availability'
            id='availability'
            placeholder={AVAILABILITY}
            value={availability}
            className={(formErrors.availability ? 'is-invalid' : '')}
            onChange={this.handleChange}
          />
          <FormFeedback>{formErrors.availability}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className='form-label'>{DESCRIPTION}</Label>
          <Input
            type='text'
            name='description'
            id='description'
            placeholder={DESCRIPTION}
            value={description}
            className={(formErrors.description ? 'is-invalid' : '')}
            onChange={this.handleChange}
          />
          <FormFeedback>{formErrors.description}</FormFeedback>
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label'>{ROHS}</Label>
              <Input
                type='text'
                name='rohs'
                id='rohs'
                placeholder={ROHS}
                value={rohs}
                className={(formErrors.rohs ? 'is-invalid' : '')}
                onChange={this.handleChange}
              />
              <FormFeedback>{formErrors.rohs}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label'>{ROHS_VERSION}</Label>
              <Input
                type='text'
                name='rohsVersion'
                id='rohsVersion'
                placeholder={ROHS_VERSION}
                value={rohsVersion}
                className={(formErrors.rohsVersion ? 'is-invalid' : '')}
                onChange={this.handleChange}
              />
              <FormFeedback>{formErrors.rohsVersion}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { billOfMaterials, isOpen, toggle } = this.props;
    const { panelTitle } = this.state;

    return (
      <Modal className='add-part-modal' isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <div className={`bill-of-materials-type ${billOfMaterials.type.toLowerCase()} mr-3`}>
            {billOfMaterials.type}
          </div>
          <div className='title-text'>
            {panelTitle}
          </div>
        </ModalHeader>
        <ModalBody>
          {this.renderForm()}
        </ModalBody>
        <ModalFooter>
          <Button
            outline
            color='primary'
            size='sm'
            onClick={this.save}
            disabled={!this.isFormValid() || !this.isFormDirty()}
          >
            {Messages.SAVE}
          </Button>
          <Button
            outline
            color='secondary'
            size='sm'
            onClick={toggle}
          >
            {Messages.CANCEL}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

PartForm.defaultProps = {
  lineItemIdentity: null,
  part: null
};

PartForm.propTypes = {
  addPart: PropTypes.func.isRequired,
  billOfMaterials: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  lineItemIdentity: PropTypes.string,
  part: PropTypes.instanceOf(Object),
  updatePart: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default PartForm;
