import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { UncontrolledTooltip } from 'reactstrap';

import { Panel, PanelBody, PanelTitle } from 'apriori-react-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDollarSign, faEraser, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  ADD_CUSTOM_PART,
  CLEAR_PART_SELECTION,
  COST_PARTS,
  EDIT_PART,
  MATCHES_FOR_PART,
  NO_PARTS_MATCHED,
  SELECT_LINE_ITEM_TO_BEGIN,
  SELECT_PART_FOR_EXPORT
} from '../../constants/messages';

import PartCard from './PartCard';
import PartForm from './PartForm';

class PartList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isPartModalVisible: false
    };
  }

  canAddCustomPart = () => this.props.selectedLineItem && this.props.selectedParts.length === 0;

  canCostParts = () => this.props.selectedParts.length > 0 && !this.isSavedPartSelected();

  canEditPart = () => this.props.selectedLineItem && this.props.selectedParts.length === 1;

  canSelectPartForExport = () => this.getSelectedPart() && this.props.selectedParts.length === 1 && (
    !this.getSelectedPart().isActionRequired
    || this.getSelectedPart().status === 'Costed');

  costParts = () => {
    const { billOfMaterials, selectedLineItem, selectedParts } = this.props;
    this.props.costParts(billOfMaterials.identity, selectedLineItem, selectedParts);
  };

  getNoContentMessage = () => {
    const { selectedLineItem } = this.props;

    if (!selectedLineItem) {
      return SELECT_LINE_ITEM_TO_BEGIN;
    }

    return NO_PARTS_MATCHED;
  };

  getSelectedLineItem = () => this.props.lineItems.find(item => item.identity === this.props.selectedLineItem);

  getSelectedPart = () => this.props.parts.find(part => part.identity === this.props.selectedParts[0]);

  getSelectedParts = () => this.props.parts.filter(part => this.props.selectedParts.includes(part.identity));

  hasContent = () => {
    const { parts } = this.props;
    return !!parts && parts.length > 0;
  };

  isSavedPartSelected = () => this.getSelectedParts().some(part => part.isSaved === true);

  selectPartForExport = () => {
    const { billOfMaterials, selectedLineItem, selectedParts } = this.props;

    if (this.canSelectPartForExport()) {
      this.props.selectPartForExport(billOfMaterials.identity, selectedLineItem, selectedParts[0]);
    }
  };

  togglePartModal = () => {
    this.setState(prevState => ({
      isPartModalVisible: !prevState.isPartModalVisible
    }));
  };

  renderAddButton = () => ([
    <button
      key='part-list-add-button'
      type='button'
      id='add-button'
      className='btn btn-outline-primary icon-button'
      onClick={this.togglePartModal}
      disabled={!this.canAddCustomPart()}
    >
      <FontAwesomeIcon icon={faPlus} />
    </button>,
    <UncontrolledTooltip
      key='part-list-add-button-tooltip'
      placement='top'
      target='add-button'
    >
      {ADD_CUSTOM_PART}
    </UncontrolledTooltip>
  ]);

  renderClearButton = () => ([
    <button
      key='part-list-clear-button'
      type='button'
      id='clear-button'
      className='btn btn-outline-primary icon-button mr-1'
      onClick={() => this.props.clearPartSelection()}
      disabled={this.props.selectedParts.length < 1}
    >
      <FontAwesomeIcon icon={faEraser} />
    </button>,
    <UncontrolledTooltip
      key='part-list-clear-tooltip'
      placement='top'
      target='clear-button'
    >
      {CLEAR_PART_SELECTION}
    </UncontrolledTooltip >
  ]);

  renderCostButton = () => ([
    <button
      key='part-list-cost-button'
      type='button'
      id='cost-button'
      className='btn btn-outline-primary icon-button mr-1'
      onClick={this.costParts}
      disabled={!this.canCostParts()}
    >
      <FontAwesomeIcon icon={faDollarSign} />
    </button>,
    <UncontrolledTooltip
      key='part-list-cost-tooltip'
      placement='top'
      target='cost-button'
    >
      {COST_PARTS}
    </UncontrolledTooltip >
  ]);

  renderEditButton = () => ([
    <button
      key='part-list-edit-button'
      type='button'
      id='edit-button'
      className='btn btn-outline-primary icon-button mr-1'
      onClick={this.togglePartModal}
      disabled={!this.canEditPart()}
    >
      <FontAwesomeIcon icon={faPencilAlt} />
    </button>,
    <UncontrolledTooltip
      key='part-list-edit-tooltip'
      placement='top'
      target='edit-button'
    >
      {EDIT_PART(this.getSelectedPart() ? this.getSelectedPart().manufacturerPartNumber : '')}
    </UncontrolledTooltip >
  ]);

  renderSelectButton = () => ([
    <button
      key='part-list-select-button'
      type='button'
      id='select-button'
      className='btn btn-outline-primary icon-button mr-1'
      onClick={this.selectPartForExport}
      disabled={!this.canSelectPartForExport()}
    >
      <FontAwesomeIcon icon={faCheck} />
    </button>,
    <UncontrolledTooltip
      key='part-list-select-button-tooltip'
      placement='top'
      target='select-button'
    >
      {SELECT_PART_FOR_EXPORT}
    </UncontrolledTooltip >
  ]);

  renderControls = () => [
    this.renderClearButton(),
    this.renderCostButton(),
    this.renderEditButton(),
    this.renderSelectButton(),
    this.renderAddButton()
  ];

  renderPanelBody = () => {
    const { billOfMaterials, parts, selectPart, selectedParts } = this.props;

    return parts.map((part) => (
      <PartCard
        key={part.identity}
        part={part}
        partClicked={selectPart}
        selectedPartIdentities={selectedParts}
        type={billOfMaterials.type}
      />
    ));
  };

  renderTitle() {
    if (this.getSelectedLineItem()) {
      const message = this.getSelectedLineItem().parts.length === 1
        ? '1 matching part found'
        : `${this.getSelectedLineItem().parts.length} matching parts found`;

      return [
        <div key='matches'>{MATCHES_FOR_PART(this.getSelectedLineItem().customerPartNumber)}</div>,
        <span key='match-count' className='subtitle'>{message}</span>
      ];
    }
  }

  render() {
    const { addPart, billOfMaterials, updatePart, selectedLineItem } = this.props;
    const { isPartModalVisible } = this.state;

    return [
      <PartForm
        key='add-part-form'
        addPart={addPart}
        billOfMaterials={billOfMaterials}
        isOpen={isPartModalVisible}
        lineItemIdentity={selectedLineItem}
        part={this.getSelectedPart()}
        updatePart={updatePart}
        toggle={this.togglePartModal}
        type={billOfMaterials.type}
      />,
      <Panel key='part-list-panel' className='part-list'>
        <PanelTitle>
          <div className='title-left'>
            {this.renderTitle()}
          </div>
          <div className='title-right'>
            {this.renderControls()}
          </div>
        </PanelTitle>
        <PanelBody hasContent={this.hasContent()} noContentMessage={this.getNoContentMessage()}>
          {this.renderPanelBody()}
        </PanelBody>
      </Panel>
    ];
  }
}

PartList.defaultProps = {
  selectedLineItem: null,
  selectedParts: []
};

PartList.propTypes = {
  addPart: PropTypes.func.isRequired,
  billOfMaterials: PropTypes.instanceOf(Object).isRequired,
  clearPartSelection: PropTypes.func.isRequired,
  costParts: PropTypes.func.isRequired,
  lineItems: PropTypes.instanceOf(Array).isRequired,
  parts: PropTypes.instanceOf(Array).isRequired,
  updatePart: PropTypes.func.isRequired,
  selectedLineItem: PropTypes.string,
  selectPart: PropTypes.func.isRequired,
  selectPartForExport: PropTypes.func.isRequired,
  selectedParts: PropTypes.instanceOf(Array)
};

export default PartList;
