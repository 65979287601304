import {
  ADD_PART_FAILED,
  ADD_PART_PENDING,
  ADD_PART_SUCCEEDED,
  COST_PARTS_FAILED,
  COST_PARTS_PENDING,
  COST_PARTS_SUCCEEDED,
  PART_SELECTED,
  PART_SELECTION_CLEARED,
  SELECT_PART_FOR_EXPORT_FAILED,
  SELECT_PART_FOR_EXPORT_PENDING,
  SELECT_PART_FOR_EXPORT_SUCCEEDED,
  UPDATE_PART_FAILED,
  UPDATE_PART_PENDING,
  UPDATE_PART_SUCCEEDED
} from '../constants/actions';

const initialState = {
  addPartRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  costPartsRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  updatePartRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  selectPartForExportRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  selectedParts: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case ADD_PART_FAILED: {
      const addPartRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        addPartRequestStatus
      };
    }

    case ADD_PART_PENDING: {
      const addPartRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        addPartRequestStatus
      };
    }

    case ADD_PART_SUCCEEDED: {
      const addPartRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        addPartRequestStatus
      };
    }

    case COST_PARTS_FAILED: {
      const costPartsRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        costPartsRequestStatus
      };
    }

    case COST_PARTS_PENDING: {
      const costPartsRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        costPartsRequestStatus
      };
    }

    case COST_PARTS_SUCCEEDED: {
      const costPartsRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        costPartsRequestStatus
      };
    }

    case PART_SELECTED: {
      const newSelectedParts = [...state.selectedParts];
      const index = newSelectedParts.indexOf(action.payload);

      if (index > -1) {
        newSelectedParts.splice(index, 1);
      }
      else {
        newSelectedParts.push(action.payload);
      }

      return {
        ...state,
        selectedParts: newSelectedParts
      }
    }

    case PART_SELECTION_CLEARED: {
      return {
        ...state,
        selectedParts: []
      }
    }

    default: {
      return state;
    }

    case UPDATE_PART_FAILED: {
      const updatePartRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        updatePartRequestStatus
      };
    }

    case UPDATE_PART_PENDING: {
      const updatePartRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        updatePartRequestStatus
      };
    }

    case UPDATE_PART_SUCCEEDED: {
      const updatePartRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        updatePartRequestStatus
      };
    }

    case SELECT_PART_FOR_EXPORT_FAILED: {
      const selectPartForExportRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        selectPartForExportRequestStatus
      };
    }

    case SELECT_PART_FOR_EXPORT_PENDING: {
      const selectPartForExportRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        selectPartForExportRequestStatus
      };
    }

    case SELECT_PART_FOR_EXPORT_SUCCEEDED: {
      const selectPartForExportRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        selectPartForExportRequestStatus
      };
    }

  }
}
