import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardText, CardBody, CardSubtitle, CardTitle, UncontrolledTooltip } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { READY_FOR_EXPORT } from '../../constants/messages';
import { renderApIcon, renderDataSheetIcon } from '../../helpers/icon-helpers'
import StatusIcon from '../widgets/StatusIcon';

class PartCard extends Component {

  cardClasses = () => this.isSelectedForCosting() ? 'part-card selected' : 'part-card';

  cardClicked = () => {
    const { part, partClicked } = this.props;
    partClicked(part.identity);
  };

  isSelectedForCosting = () => {
    const { part, selectedPartIdentities } = this.props;
    return selectedPartIdentities.includes(part.identity);
  };

  isReadyForExport = () => {
    const { part } = this.props;
    return part.status === READY_FOR_EXPORT;
  };

  renderAddedByIcon = () => {
    const { part } = this.props;

    if (part.isUserPart) {
      return (
        <div className='user-icon'>
          <FontAwesomeIcon id={`user-icon-${part.identity}`} icon={faUser} />
          <UncontrolledTooltip
            placement='top'
            target={`user-icon-${part.identity}`}
          >
            Added by user
          </UncontrolledTooltip>
        </div>
      );
    }

    return renderApIcon(part.identity);
  };

  renderProperty = (name, value, isRequired, valuePrefix = '') => {

    if (value) {
      return (
        <CardText className='property'>
          <span className='title'>{name}</span>
          <span className='value'>{valuePrefix}{value}</span>
        </CardText>
      );
    }

    if (isRequired) {
      return (
        <CardText className='property'>
          <span className='title'>{name}</span>
          <span className='value missing'>Missing</span>
        </CardText>
      );
    }
  };

  render() {
    const { part, type } = this.props;

    return (
      <Card tag='span' className={this.cardClasses()} id={part.identity} onClick={this.cardClicked}>
        <CardBody>
          <CardTitle>
            <div className='title-left'>
              <StatusIcon status={part.status} uniqueId={part.identity} />
              <span>{part.manufacturerPartNumber}</span>
            </div>
            <div className='title-right'>
              <small className='text-muted mr-3'>{part.classification}</small>
              {this.renderAddedByIcon()}
            </div>
          </CardTitle>
          <div className='divider' />
          <CardSubtitle>
            {part.description}
          </CardSubtitle>
          <div className='properties'>
            {renderDataSheetIcon(part.dataSheetUrl)}
            {this.renderProperty('Average Cost', part.averageCost, true, '$')}
            {this.renderProperty('Minimum Cost', part.minimumCost, false, '$')}
            {type === 'PCBA' && this.renderProperty('Mount Type', part.mountType, true)}
            {type === 'PCBA' && this.renderProperty('Pin Count', part.pinCount, true)}
            {this.renderProperty('Manufacturer', part.manufacturer, false)}
            {this.renderProperty('Availability', part.availability, false)}
            {this.renderProperty('RoHS', part.rohs, false)}
            {this.renderProperty('RoHS Version', part.rohsVersion, false)}
          </div>
        </CardBody>
      </Card>
    );
  }
}

PartCard.defaultProps = {
  selectedPartIdentities: []
};

PartCard.propTypes = {
  part: PropTypes.instanceOf(Object).isRequired,
  partClicked: PropTypes.func.isRequired,
  selectedPartIdentities: PropTypes.instanceOf(Array),
  type: PropTypes.string.isRequired
};

export default PartCard;
