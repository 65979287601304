import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Progress } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { FormatHelpers } from 'apriori-react-core';

import {
  INVALID_ACTIVE_ACCOUNT_CREDENTIALS,
  PART_REQUESTS_USED,
  PART_REQUESTS_USED_UNLIMITED,
  RETRIEVING_ACTIVE_ACCOUNT,
  RETRIEVING_ACTIVE_ACCOUNT_FAILED
} from '../../constants/messages';

const LICENSE_UNLIMITED = -1;
const COLOR_INFO = 'info';
const COLOR_WARNING = 'warning';
const COLOR_DANGER = 'danger';

class ActiveAccount extends Component {

  componentDidMount() {
    const { account } = this.props;
    if (!account.isValid) {
      this.props.getAccountStatus();
    }
  }

  render() {
    if (this.props.failed) {
      return (
        <div className='active-account border-light'>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span>{RETRIEVING_ACTIVE_ACCOUNT_FAILED}</span>
        </div>
      );
    }

    if (this.props.pending) {
      return (
        <div className='active-account border-light'>
          <FontAwesomeIcon icon={faCircleNotch} spin />
          <span>{RETRIEVING_ACTIVE_ACCOUNT}</span>
        </div>
      );
    }

    const { account } = this.props;

    if (!account.isValid) {
      return (
        <div className='active-account border-light'>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span>{INVALID_ACTIVE_ACCOUNT_CREDENTIALS}</span>
        </div>
      );
    }

    return (
      <div className='active-account border-light'>
        {this.renderProgressBar(account)}
      </div>
    );
  }

  renderProgressBar(account) {

    const { licenseLimit, licenseUsage } = account;

    if (licenseLimit === LICENSE_UNLIMITED) {
      return (
        <Progress value={0} color={COLOR_INFO}>
          <span className='progress-message unlimited-license'>
            {licenseUsage} {PART_REQUESTS_USED_UNLIMITED}
          </span>
        </Progress>
      );
    }

    const percentRemaining = ((licenseUsage / licenseLimit) * 100.0).toFixed(2);

    let color = {COLOR_INFO};
    if (percentRemaining >= 80 && percentRemaining < 95) {
      color = {COLOR_WARNING};
    }
    if (percentRemaining >= 95) {
      color = {COLOR_DANGER};
    }

    return (
      <Progress value={percentRemaining} color={color}>
        <span className='progress-message'>
          {`${FormatHelpers.formatAsDecimal(percentRemaining)}% of ${FormatHelpers.formatAsInteger(licenseLimit)} ${PART_REQUESTS_USED}`}
        </span>
      </Progress>
    );
  }
}

ActiveAccount.propTypes = {
  account: PropTypes.instanceOf(Object).isRequired,
  failed: PropTypes.bool.isRequired,
  getAccountStatus: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired
};

export default ActiveAccount;
