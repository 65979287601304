import {
  ACCOUNT_SELECTED,
  ACTIVATE_ACCOUNT_FAILED,
  ACTIVATE_ACCOUNT_PENDING,
  ACTIVATE_ACCOUNT_SUCCEEDED,
  ADD_ACCOUNT_FAILED,
  ADD_ACCOUNT_PENDING,
  ADD_ACCOUNT_SUCCEEDED,
  DELETE_ACCOUNT_FAILED,
  DELETE_ACCOUNT_PENDING,
  DELETE_ACCOUNT_SUCCEEDED,
  GET_ACTIVE_ACCOUNT_FAILED,
  GET_ACTIVE_ACCOUNT_PENDING,
  GET_ACTIVE_ACCOUNT_SUCCEEDED,
  LIST_ACCOUNTS_FAILED,
  LIST_ACCOUNTS_PENDING,
  LIST_ACCOUNTS_SUCCEEDED,
  UPDATE_ACCOUNT_FAILED,
  UPDATE_ACCOUNT_PENDING,
  UPDATE_ACCOUNT_SUCCEEDED
} from '../constants/actions';

const initialState = {
  activeAccount: {},
  accounts: [],
  activateAccountRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  addAccountRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  deleteAccountRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  getActiveAccountRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  listAccountsRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  updateAccountRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  selectedAccount: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case ACCOUNT_SELECTED: {
      return {
        ...state,
        selectedAccount: action.payload
      };
    }

    case ACTIVATE_ACCOUNT_FAILED: {
      const activateAccountRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        activateAccountRequestStatus
      };
    }

    case ACTIVATE_ACCOUNT_PENDING: {
      const activateAccountRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        activateAccountRequestStatus
      };
    }

    case ACTIVATE_ACCOUNT_SUCCEEDED: {
      const activateAccountRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        activateAccountRequestStatus
      };
    }

    case ADD_ACCOUNT_FAILED: {
      const addAccountRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        addAccountRequestStatus
      };
    }

    case ADD_ACCOUNT_PENDING: {
      const addAccountRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        addAccountRequestStatus
      };
    }

    case ADD_ACCOUNT_SUCCEEDED: {
      const addAccountRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        addAccountRequestStatus
      };
    }

    case DELETE_ACCOUNT_FAILED: {
      const deleteAccountRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        deleteAccountRequestStatus
      };
    }

    case DELETE_ACCOUNT_PENDING: {
      const deleteAccountRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        deleteAccountRequestStatus
      };
    }

    case DELETE_ACCOUNT_SUCCEEDED: {
      const deleteAccountRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        deleteAccountRequestStatus
      };
    }

    case GET_ACTIVE_ACCOUNT_FAILED: {
      const getActiveAccountRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        getActiveAccountRequestStatus
      };
    }

    case GET_ACTIVE_ACCOUNT_PENDING: {
      const getActiveAccountRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        getActiveAccountRequestStatus
      };
    }

    case GET_ACTIVE_ACCOUNT_SUCCEEDED: {
      const getActiveAccountRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        activeAccount: Object.assign({}, action.payload),
        getActiveAccountRequestStatus
      };
    }

    case LIST_ACCOUNTS_FAILED: {
      const listAccountsRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        listAccountsRequestStatus
      };
    }

    case LIST_ACCOUNTS_PENDING: {
      const listAccountsRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        listAccountsRequestStatus
      };
    }

    case LIST_ACCOUNTS_SUCCEEDED: {
      const listAccountsRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        accounts: action.payload.items,
        listAccountsRequestStatus
      };
    }

    case UPDATE_ACCOUNT_FAILED: {
      const updateAccountRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        updateAccountRequestStatus
      };
    }

    case UPDATE_ACCOUNT_PENDING: {
      const updateAccountRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        updateAccountRequestStatus
      };
    }

    case UPDATE_ACCOUNT_SUCCEEDED: {
      const updateAccountRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        updateAccountRequestStatus
      };
    }

    default: {
      return state;
    }

  }
}
