// Account
export const ACCOUNT_SELECTED = 'ACCOUNT_SELECTED';
export const ACTIVATE_ACCOUNT_FAILED = 'ACTIVATE_ACCOUNT_FAILED';
export const ACTIVATE_ACCOUNT_PENDING = 'ACTIVATE_ACCOUNT_PENDING';
export const ACTIVATE_ACCOUNT_SUCCEEDED = 'ACTIVATE_ACCOUNT_SUCCEEDED';
export const ADD_ACCOUNT_FAILED = 'ADD_ACCOUNT_FAILED';
export const ADD_ACCOUNT_PENDING = 'ADD_ACCOUNT_PENDING';
export const ADD_ACCOUNT_SUCCEEDED = 'ADD_ACCOUNT_SUCCEEDED';
export const DELETE_ACCOUNT_FAILED = 'DELETE_ACCOUNT_FAILED';
export const DELETE_ACCOUNT_PENDING = 'DELETE_ACCOUNT_PENDING';
export const DELETE_ACCOUNT_SUCCEEDED = 'DELETE_ACCOUNT_SUCCEEDED';
export const GET_ACTIVE_ACCOUNT_FAILED = 'GET_ACTIVE_ACCOUNT_FAILED';
export const GET_ACTIVE_ACCOUNT_PENDING = 'GET_ACTIVE_ACCOUNT_PENDING';
export const GET_ACTIVE_ACCOUNT_SUCCEEDED  = 'GET_ACTIVE_ACCOUNT_SUCCEEDED';
export const LIST_ACCOUNTS_FAILED = 'LIST_ACCOUNTS_FAILED';
export const LIST_ACCOUNTS_PENDING = 'LIST_ACCOUNTS_PENDING';
export const LIST_ACCOUNTS_SUCCEEDED = 'LIST_ACCOUNTS_SUCCEEDED';
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED';
export const UPDATE_ACCOUNT_PENDING = 'UPDATE_ACCOUNT_PENDING';
export const UPDATE_ACCOUNT_SUCCEEDED = 'UPDATE_ACCOUNT_SUCCEEDED';

// Authentication
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const AUTHENTICATION_PENDING = 'AUTHENTICATION_PENDING';
export const AUTHENTICATION_SUCCEEDED = 'AUTHENTICATION_SUCCEEDED';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const SESSION_CHECK_INITIATED = 'SESSION_CHECK_INITIATED';

// Bill of Materials
export const DELETE_BILL_OF_MATERIALS_FAILED = 'DELETE_BILL_OF_MATERIALS_FAILED';
export const DELETE_BILL_OF_MATERIALS_PENDING = 'DELETE_BILL_OF_MATERIALS_PENDING';
export const DELETE_BILL_OF_MATERIALS_SUCCEEDED = 'DELETE_BILL_OF_MATERIALS_SUCCEEDED';
export const EXPORT_BILL_OF_MATERIALS_FAILED = 'EXPORT_BILL_OF_MATERIALS_FAILED';
export const EXPORT_BILL_OF_MATERIALS_PENDING = 'EXPORT_BILL_OF_MATERIALS_PENDING';
export const EXPORT_BILL_OF_MATERIALS_SUCCEEDED = 'EXPORT_BILL_OF_MATERIALS_SUCCEEDED';
export const GET_BILL_OF_MATERIALS_FAILED = 'GET_BILL_OF_MATERIALS_FAILED';
export const GET_BILL_OF_MATERIALS_PENDING = 'GET_BILL_OF_MATERIALS_PENDING';
export const GET_BILL_OF_MATERIALS_SUCCEEDED = 'GET_BILL_OF_MATERIALS_SUCCEEDED';
export const LIST_BILL_OF_MATERIALS_FAILED = 'LIST_BILL_OF_MATERIALS_FAILED';
export const LIST_BILL_OF_MATERIALS_PENDING = 'LIST_BILL_OF_MATERIALS_PENDING';
export const LIST_BILL_OF_MATERIALS_SUCCEEDED = 'LIST_BILL_OF_MATERIALS_SUCCEEDED';
export const UPLOAD_BILL_OF_MATERIALS_FAILED = 'UPLOAD_BILL_OF_MATERIALS_FAILED';
export const UPLOAD_BILL_OF_MATERIALS_PENDING = 'UPLOAD_BILL_OF_MATERIALS_PENDING';
export const UPLOAD_BILL_OF_MATERIALS_SUCCEEDED = 'UPLOAD_BILL_OF_MATERIALS_SUCCEEDED';

// Line Item
export const LINE_ITEM_COST_STATUS_FILTER_APPLIED = 'LINE_ITEM_COST_STATUS_FILTER_APPLIED';
export const LINE_ITEM_FILTER_RESET = 'LINE_ITEM_FILTER_RESET';
export const LINE_ITEM_SEARCH_APPLIED = 'LINE_ITEM_SEARCH_APPLIED';
export const LINE_ITEM_SELECTED = 'LINE_ITEM_SELECTED';
export const LINE_ITEM_CLEARED = 'LINE_ITEM_CLEARED';
export const LIST_LINE_ITEMS_FAILED = 'LIST_LINE_ITEMS_FAILED';
export const LIST_LINE_ITEMS_PENDING = 'LIST_LINE_ITEMS_PENDING';
export const LIST_LINE_ITEMS_SUCCEEDED = 'LIST_LINE_ITEMS_SUCCEEDED';

// Part
export const ADD_PART_FAILED = 'ADD_PART_FAILED';
export const ADD_PART_PENDING = 'ADD_PART_PENDING';
export const ADD_PART_SUCCEEDED = 'ADD_PART_SUCCEEDED';
export const COST_PARTS_FAILED = 'COST_PARTS_FAILED';
export const COST_PARTS_PENDING = 'COST_PARTS_PENDING';
export const COST_PARTS_SUCCEEDED = 'COST_PARTS_SUCCEEDED';
export const PART_SELECTED = 'PART_SELECTED';
export const PART_SELECTION_CLEARED = 'PART_SELECTION_CLEARED';
export const UPDATE_PART_FAILED = 'UPDATE_PART_FAILED';
export const UPDATE_PART_PENDING = 'UPDATE_PART_PENDING';
export const UPDATE_PART_SUCCEEDED = 'UPDATE_PART_SUCCEEDED';
export const SELECT_PART_FOR_EXPORT_FAILED = 'SELECT_PART_FOR_EXPORT_FAILED';
export const SELECT_PART_FOR_EXPORT_PENDING = 'SELECT_PART_FOR_EXPORT_PENDING';
export const SELECT_PART_FOR_EXPORT_SUCCEEDED = 'SELECT_PART_FOR_EXPORT_SUCCEEDED';
