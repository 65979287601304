import { EnvironmentHelpers } from 'apriori-react-core';

const EDC_API = EnvironmentHelpers.getEnvironmentVariable('REACT_APP_SUPPORTING_API_URL');

export const ACCOUNTS_URL =
  `${EDC_API}/accounts`;

export const ACTIVE_ACCOUNT_URL =
  `${ACCOUNTS_URL}/active`;

export const BILL_OF_MATERIALS_URL =
  `${EDC_API}/bill-of-materials`;

export const CURRENT_USER_URL =
  `${EDC_API}/users/current`;

export const LINE_ITEMS_URL = (billOfMaterialsIdentity) =>
  `${EDC_API}/bill-of-materials/${billOfMaterialsIdentity}/line-items`;

export const PARTS_URL = (billOfMaterialsIdentity, lineItemIdentity) =>
  `${EDC_API}/bill-of-materials/${billOfMaterialsIdentity}/line-items/${lineItemIdentity}/parts`;

export const PART_URL = (billOfMaterialsIdentity, lineItemIdentity, partIdentity) =>
  `${PARTS_URL(billOfMaterialsIdentity, lineItemIdentity)}/${partIdentity}`;
